import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Comman/Sidebar";
import Header from "./Comman/Header";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";
import Pagination from "./Comman/Pagination";

export default function Staking() {
  const { account,copyaddress,formatAddress} = useContext(OnmaxContext);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "stake",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,

      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));

      });
  };
  useEffect(() => {
    getData();
  }, [account,pages, pageSize, currentPage]);
  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-table mg-top-40">
                      <div className="nftmax-table__heading">
                        <h3 className="nftmax-table__title mb-0">Staking </h3>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="table_1"
                          role="tabpanel"
                          aria-labelledby="table_1"
                        >
                          <table
                            id="nftmax-table__main"
                            className="nftmax-table__main nftmax-table__main-v1"
                          >
                            <thead className="nftmax-table__head">
                              <tr>
                                <th className="nftmax-table__column-1 nftmax-table__h1">
                                  Sr.
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                Reward Eligibility
                                </th>
                                <th className="nftmax-table__column-4 nftmax-table__h4">
                                  Stake($)
                                </th>
                                <th className="nftmax-table__column-5 nftmax-table__h5">
                                  Rate
                                </th>
                                <th className="nftmax-table__column-6 nftmax-table__h6">
                                  Block
                                </th>
                                <th className="nftmax-table__column-7 nftmax-table__h7">
                                  Datetime
                                </th>
                                <th className="nftmax-table__column-8 nftmax-table__h8">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tr className="text-center">
                            {isLoading ? "Data is loading" : ""}
                            </tr>
                            {!isLoading ? (
                              !data.length ? (
                                <tr className="text-center">
                                  <td className="text-center" colSpan={7}>
                                    <span className="w-100">No data found</span>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <tbody className="nftmax-table__body">
                              {data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td className="nftmax-table__column-1 nftmax-table__data-1">
                                      <div className="nftmax-table__text">
                                        {ele.id}
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-3 nftmax-table__data-3">
                                      <div className="nftmax-table__amount nftmax-table__text-two">
                                        <span className="nftmax-table__text">
                                          {ele.income_capability}x
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-4 nftmax-table__data-4">
                                      <span className="nftmax-table__text">
                                        $ {ele.amount}
                                        <br />
                                        <span className="nftmax-table__text" onClick={() =>
                                        copyaddress(ele.hash)
                                      }>
                                        {formatAddress(ele.hash)}  <i className="fa fa-copy"></i>
                                      </span>
                                      </span>
                                    </td>
                                    <td className="nftmax-table__column-5 nftmax-table__data-5">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                        $ {ele.token_rate}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-6 nftmax-table__data-6">
                                      <p className="nftmax-table__text nftmax-table__time">
                                        {ele.b_type === 0
                                          ? "Block A"
                                          : "Block B"}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-7 nftmax-table__data-7">
                                      <div className="nftmax-table__text">
                                        Start : {ele.start_time}
                                        <br />
                                        End : {ele.end_time}
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-8 nftmax-table__data-8">
                                      {ele.status===1 ? (
                                        <div className="nftmax-table__status nftmax-sbcolor">
                                          Active
                                        </div>
                                      ) : (
                                        <div className="nftmax-table__status nftmax-rbcolor">
                                          Over
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pages={pages}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
