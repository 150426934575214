import React, { useContext, useEffect, useState } from "react";
import { OnmaxContext } from "../../OnmaxContext";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const { disconnectNow, tokenrate, DBbalance, dbuser } = useContext(OnmaxContext);

  var navigate = useNavigate();
  const logout = () => {
    disconnectNow();
    navigate("/");
  };
  const [show, setShow] = useState(false);
  const handleClickShowMenu = () => {
    setShow(true);

    // console.log("click");
  };
  const handleClickShowClose = () => {
    setShow(false);

    // console.log("click");
  };
  const currentPath = window.location.pathname;
  useEffect(() => {
    var iscon = localStorage.getItem("isconnected");
    // console.log("iscon", iscon);
    const resultString = currentPath.slice(0, 6);

    if (resultString === "/stake") {
      navigate(currentPath);
    } else {
      if (iscon !== "true") {
        navigate("/");
      }
    }
  }, []);
  return (
    <>
      <div className={show ? "nftmax-smenu nftmax-close" : "nftmax-smenu"}>
        <div className="admin-menu">
          <div className="logo">
            <Link to="/">
              <img
                className="nftmax-logo__main"
                src="../assets/img/logo-white.png"
                alt="#"
              />
            </Link>
            {show ? (
              <div className="nftmax__sicon close-icon">
                <img
                  src="../assets/img/close.svg"
                  alt="#"
                  onClick={handleClickShowClose}
                />
              </div>
            ) : (
              <div className="nftmax__sicon close-icon">
                <img
                  src="../assets/img/menu-toggle.svg"
                  alt="#"
                  onClick={handleClickShowClose}
                />
              </div>
            )}
          </div>
          <div className="admin-menu__one">
            <h4 className="admin-menu__title nftmax-scolor">Menu</h4>
            <div className="menu-bar">
              <ul className="menu-bar__one">
                <li
                  className={`${currentPath === "/dashboard" ? "active" : ""}`}
                >
                  <Link to="/dashboard">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v1">
                        <img
                          src="../assets/img/icon/staking.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Dashboard</span>
                    </span>
                  </Link>
                </li>
                <li className={`${currentPath === "/staking" ? "active" : ""}`}>
                  <Link to="/staking">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v1">
                        <img
                          src="../assets/img/icon/staking.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Staking</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/refferal" ? "active" : ""}`}
                >
                  <Link to="/refferal">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v3">
                        <img
                          src="../assets/img/icon/staking.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Team</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/staking-income" ? "active" : ""
                  }`}
                >
                  <Link to="/staking-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v4">
                        <img
                          src="../assets/img/icon/staking_income.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Staking Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/level-income" ? "active" : ""
                  }`}
                >
                  <Link to="/level-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v4">
                        <img
                          src="../assets/img/icon/level_income.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Aggregator Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/level-ranks" ? "active" : ""
                  }`}
                >
                  <Link to="/level-ranks">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v4">
                        <img
                          src="../assets/img/icon/level_income.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Aggregator Ranks</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/heritage-income" ? "active" : ""
                  }`}
                >
                  <Link to="/heritage-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v5">
                        <img
                          src="../assets/img/icon/heritage_income.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Cold Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/community-income" ? "active" : ""
                  }`}
                >
                  <Link to="/community-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v6">
                        <img
                          src="../assets/img/icon/community.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Mining Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/community_royalty" ? "active" : ""
                  }`}
                >
                  <Link to="/community-royalty">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v6">
                        <img
                          src="../assets/img/icon/community_royality.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Rank Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/wtihdrawal" ? "active" : ""}`}
                >
                  <Link to="/wtihdrawal">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <img
                          src="../assets/img/icon/withdraw.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Withdrawal</span>
                    </span>
                  </Link>
                </li>
                <li className={`${currentPath === "/ledger" ? "active" : ""}`}>
                  <Link to="/ledger">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <img
                          src="../assets/img/icon/ledger.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Ledger</span>
                    </span>
                  </Link>
                </li>
                <li className={`${currentPath === "/unstake" ? "active" : ""}`}>
                  <Link to="/unstake">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <img
                          src="../assets/img/icon/unstake.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Unstake</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <a
                    href="/assets/onmax_affiliate_presenataion.pdf"
                    target="_blank"
                  >
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <img
                          src="../assets/img/icon/presentation.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Presentation</span>
                    </span>
                  </a>
                </li>
                <li>
                  <Link to="https://help.onmaxyield.com/" target="_blank">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <img
                          src="../assets/img/icon/help.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Help</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="admin-menu__two mg-top-50">
            <h4 className="admin-menu__title nftmax-scolor">Settings</h4>
            <div className="menu-bar">
              <ul className="menu-bar__one">
                <li>
                  <Link to="/profile">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v10">
                        <img
                          src="../assets/img/icon/Setting.svg"
                          width={25}
                          alt=""
                          srcset=""
                        />
                      </span>
                      <span className="menu-bar__name">Portfolio</span>{" "}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="logout-button">
            <button
              className="nftmax-btn primary"
              onClick={() => logout()}
              typeof="button"
            >
              <div className="logo-button__icon">
                <img src="../assets/img/logout.png" alt="#" />
              </div>
              <span className="menu-bar__name">Signout</span>
            </button>
          </div>
        </div>
      </div>
      <header className={show ? "nftmax-header nftmax-close" : "nftmax-header"}>
        <div className="container">
          <div className="row g-50">
            <div className="col-12">
              <div className="nftmax-header__inner">
                <div
                  className="nftmax__sicon close-icon d-xl-none"
                  onClick={handleClickShowMenu}
                >
                  <img src="../assets/img/menu-toggle.svg" alt="#" />
                </div>
                <div className="nftmax-header__left">
                  <div className="nftmax-header__form d-none">
                    <form className="nftmax-header__form-inner" action="#">
                      <button className="search-btn" type="submit">
                        <img src="../assets/img/search.png" alt="#" />
                      </button>
                      <input
                        name="s"
                        defaultValue
                        type="text"
                        placeholder="Search items, collections..."
                      />
                    </form>
                  </div>
                </div>
                <div className="nftmax-header__right">
                  <div className="nftmax-header__group">
                    <div className="nftmax-header__amount">
                      <div className="nftmax-amount__digit">
                        <span>$</span> {tokenrate}{" "}
                        {process.env.REACT_APP_TICKER}
                      </div>
                      <br />
                    </div>
                    <div className="nftmax-header__amount">
                      <div className="nftmax-amount__icon">
                        <img src="../assets/img/bag-icon.svg" alt="#" />
                      </div>
                      <div className="nftmax-amount__digit">
                        <span>$</span> {DBbalance}
                      </div>
                      <br />
                    </div>
                    <div className="nftmax-header__group-two">
                      <div className="nftmax-header__right">
                        <div className="nftmax-header__author">
                          <div className="nftmax-header__author-img">
                            <div className="animated-image-container">
                              <img
                                src="../assets/img/logo-footer.png"
                                className="animated-image"
                                alt="#"
                              />
                            </div>
                          </div>
                          <div className="nftmax-header__author-content">
                            <p className="nftmax-header__author-text v1 pe-2">
                              Hello
                            </p>
                            <h4 className="nftmax-header__author-title">
                              {dbuser !== null
                                ? dbuser.name !== null
                                  ? dbuser.name
                                  : "ONMAXIER"
                                : "ONMAXIER"}
                              &nbsp;&nbsp;&nbsp;
                            </h4>
                          </div>
                          <div className="nftmax-balance nftmax-profile__hover">
                            <ul className="nftmax-balance_list">
                              <li>
                                <div className="nftmax-balance-info">
                                  <div className="nftmax-balance__img nftmax-profile__img-five">
                                    <img
                                      src="../assets/img/profile-5.png"
                                      alt="#"
                                    />
                                  </div>
                                  <h4
                                    className="nftmax-balance-name"
                                    type="button"
                                    onClick={() => logout()}
                                  >
                                    Log Out
                                  </h4>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
