/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import Header from "./Comman/Header";
import Sidebar from "./Comman/Sidebar";
import { OnmaxContext } from "../OnmaxContext";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Dashboard() {
  const { rate, copyaddress, DBbalance, account, dbuser, smuser } =
    useContext(OnmaxContext);
  const [data, setdata] = useState([]);
  const getData = async () => {
    if (!account) {
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "today",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: 1,
        pageSize: 100,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, [account]);
  return (
    <>
      <div className="body-bg">
        <Sidebar />

        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-9 col-lg-12 col-md-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-wallet__dashboard">
                      <div className="row nftmax-gap-40">
                        <div className="col-lg-6 col-md-6 col-12 nftmax-wallet__on">
                          <div className="nftmax-balance__current nftmax-bg__cover mg-top-30">
                            <div className="nftmax-balancetop">
                              <div className="nftmax-balance__wallet-box">
                                {!dbuser
                                  ? "No"
                                  : dbuser.rank
                                    ? dbuser.rank
                                    : "No"}{" "}
                                <span>Rank</span>
                              </div>
                              {/* <div className="nftmax-transaction">
                                <h3 className="nftmax-transaction__title">
                                  ${rate}
                                </h3>
                                <p className="nftmax-transaction__text">
                                  {process.env.REACT_APP_TICKER} Rate
                                </p>
                              </div> */}
                            </div>
                            <div className="nftmax-balance__wallet-current">
                              <p className="nftmax-balance__wallet-text">
                                Balance
                              </p>
                              <h2 className="nftmax-balance__wallet-amount">
                                $ {DBbalance}
                              </h2>
                            </div>
                            <br />
                            <div className="nftmax-progress d-flex justify-content-between">
                              <div className="nftmax-progress__single">
                                <div
                                  className="circle circle__one"
                                  data-value="0.9"
                                  data-size={65}
                                >
                                  <canvas width={65} height={65} />
                                  <b className="number">
                                    ${" "}
                                    {dbuser !== null
                                      ? dbuser.required_business
                                      : "0"}
                                  </b>
                                </div>
                                <strong>Required Business</strong>
                              </div>
                              <div className="nftmax-progress__single">
                                <div
                                  className="circle circle__two"
                                  data-value="0.4"
                                  data-size={65}
                                >
                                  <canvas width={65} height={65} />
                                  <b className="number">
                                    {dbuser !== null
                                      ? Number(dbuser.required_teamInStrong) +
                                      Number(dbuser.required_teamInOther)
                                      : "0"}
                                  </b>
                                </div>
                                <strong>Required Team</strong>
                              </div>
                              {/* <div className="nftmax-progress__single">
                                <div
                                  className="circle circle__two"
                                  data-value="0.4"
                                  data-size={65}
                                >
                                  <canvas width={65} height={65} />
                                  <b className="number">{dbuser !== null ? dbuser.next_rank: "0"}</b>
                                </div>
                                <strong>Next Rank</strong>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 nftmax-wallet__tw d-flex justify-content-center">
                          <div className="nftmax-balance__all mg-top-30">
                            {/* <h3 className="nftmax-balance__all-title mb-5 text-center">My Wallet</h3> */}
                            <ul className="nftmax-balance_list mt-2 text-center">
                              <li className="nftmax-balance__all-single d-flex flex-wrap justify-content-center">
                                <div className="nftmax-balance-info">
                                  {/* <div className="nftmax-balance__img"><img src="assets/img/wallet-1.png" alt="#" /></div> */}
                                  <h4 className="nftmax-balance-amount">
                                    Validator
                                  </h4>
                                </div>
                                <div className="nftmax-balance-desc">
                                  <div className="nftmax-balance-desc__main">
                                    <p
                                      className="nftmax-balance__sub"
                                      onClick={() =>
                                        copyaddress(dbuser.ref_address)
                                      }n
                                    >
                                      <b>
                                        {dbuser !== null
                                          ? dbuser.ref_address
                                          : "-"}{" "}
                                        <i className="fa fa-copy"></i>
                                      </b>
                                    </p>
                                    {/* <h4 className="nftmax-balance-amount"><b>$</b>734.79</h4> */}
                                  </div>
                                </div>
                              </li>
                              <li className="nftmax-balance__all-single d-flex flex-wrap justify-content-center">
                                <div className="nftmax-balance-info">
                                  {/* <div className="nftmax-balance__img"><img src="assets/img/wallet-1.png" alt="#" /></div> */}
                                  <h4 className="nftmax-balance-amount">
                                    Deligator
                                  </h4>
                                </div>
                                <div className="nftmax-balance-desc">
                                  <div className="nftmax-balance-desc__main">
                                    {/* <h4 className="nftmax-balance-amount"><b>$</b>734.79</h4> */}
                                    <p
                                      className="nftmax-balance__sub"
                                      onClick={() => copyaddress(account)}
                                    >
                                      <b>
                                        {account} <i className="fa fa-copy"></i>
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </li>
                              {/* <li className="nftmax-balance__all-single d-flex flex-wrap justify-content-center">
                                <div className="nftmax-balance-info">
                                  <h4 className="nftmax-balance-amount">
                                    Contract Address
                                  </h4>
                                </div>
                                <div className="nftmax-balance-desc">
                                  <div className="nftmax-balance-desc__main">
                                    <p
                                      className="nftmax-balance__sub"
                                      onClick={() =>
                                        copyaddress(smuser.contractaddress)
                                      }
                                    >
                                      <b>
                                        {smuser !== null
                                          ? smuser.contractaddress
                                          : ""}{" "}
                                        <i className="fa fa-copy"></i>
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </li> */}
                              <li className="nftmax-balance__all-single d-flex justify-content-center">
                                <div className="d-flex mt-2 justify-content-center">
                                  {/* <div className="nftmax-amount__icon"><img src="assets/img/bag-icon.svg" alt="#" /></div> */}
                                  {dbuser !== null ? (
                                    dbuser.ref_address ? (
                                      dbuser.restake ? (
                                        <div className="nftmax-header__amount mx-2">
                                          <Link to="/stake">
                                            <div className="nftmax-amount__digit ">
                                              <span></span> Re Stake Now &nbsp;
                                            </div>
                                          </Link>
                                        </div>
                                      ) : (
                                        <div className="nftmax-header__amount mx-2 ">
                                          <a>
                                            <div className="nftmax-amount__digit ">
                                              <span></span> Stake Now &nbsp;
                                            </div>
                                          </a>
                                        </div>
                                      )
                                    ) : (
                                      <div className="nftmax-header__amount mx-2">
                                        <Link to="/stake">
                                          <div className="nftmax-amount__digit ">
                                            <span></span> Stake Now &nbsp;
                                          </div>
                                        </Link>
                                      </div>
                                    )
                                  ) : (
                                    <div className="nftmax-header__amount mx-2">
                                      <Link to="/stake">
                                        <div className="nftmax-amount__digit ">
                                          <span></span> Stake Now &nbsp;
                                        </div>
                                      </Link>
                                    </div>
                                  )}

                                  <br />
                                  <div className="nftmax-header__amount mx-2">
                                  <Link to="/wtihdrawal">
                                    <div className="nftmax-amount__digit">
                                      <span></span> Withdrawal
                                    </div>
                                    </Link>
                                    <br />
                                  </div>
                                </div>
                              </li>
                              <li className="nftmax-balance__all-single d-flex justify-content-center">
                                <div className="d-flex mt-2 justify-content-center">
                                  <div className="nftmax-header__amount mx-2">
                                    <Link className="nftmax-amount__digit" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}stake/${account}`)}>
                                      <span></span>Sponsor Link
                                    </Link>
                                    <br />
                                  </div>
                                  <div className="nftmax-header__amount mx-2">
                                    <Link className="nftmax-amount__digit" to="https://pancakeswap.finance/swap?inputCurrency=0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A&outputCurrency=0x55d398326f99059fF775485246999027B3197955" target="_blank">
                                      <span></span>Buy OMP
                                    </Link>
                                    <br />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row nftmax-gap-30">
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-four">
                                  <img
                                    src="assets/img/svg/validator.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      {" "}
                                      {dbuser !== null ? dbuser.ref_team : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Validators
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-one">
                                  <img
                                    src="assets/img/svg/team.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      {" "}
                                      {dbuser !== null ? dbuser.team : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">Team</p>
                                  <p className="nftmax-history__amount" />
                                </div>
                              </div>
                              <div className="nftmax-history__canvas">
                                <div className="charts-main__one">
                                  <canvas
                                    id="myChart_history_one"
                                    width={224}
                                    height={112}
                                    style={{
                                      display: "none",
                                      boxSizing: "border-box",
                                      height: "112px",
                                      width: "224px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-two">
                                  <img
                                    src="assets/img/svg/stake.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null ? Number(dbuser.t_staking).toFixed(2) : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">Stake</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-three">
                                  <img
                                    src="assets/img/svg/total_reward.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">${Number(DBbalance).toFixed(2)}</span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Total Reward
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-four">
                                  <img
                                    src="assets/img/svg/staking_reward.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.staking_rew).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Staking Reward
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-four">
                                  <img
                                    src="assets/img/svg/aggregrator.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null ? Number(dbuser.level_rew).toFixed(2) : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Aggregator
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-one">
                                  <img
                                    src="assets/img/svg/cold_reward.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.heritage_rew).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Cold Reward
                                  </p>
                                  <p className="nftmax-history__amount" />
                                </div>
                              </div>
                              <div className="nftmax-history__canvas">
                                <div className="charts-main__one">
                                  <canvas
                                    id="myChart_history_one"
                                    width={224}
                                    height={112}
                                    style={{
                                      display: "none",
                                      boxSizing: "border-box",
                                      height: "112px",
                                      width: "224px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-two">
                                  <img
                                    src="assets/img/svg/mining_reward.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.community_rew).toFixed(
                                          2
                                        )
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Mining Reward
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-three">
                                  <img
                                    src="assets/img/svg/rank_reward.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.community_royalty).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Rank Reward
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-four">
                                  <img
                                    src="assets/img/svg/strong_leg.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.next_strong_leg).toFixed(2)
                                        : "0"}{" "}
                                      {/* (
                                      {dbuser !== null
                                        ? dbuser.next_strong_leg_users
                                        : "0"}{" "}
                                      ) */}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Strong Leg
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-four">
                                  <img
                                    src="assets/img/svg/strong_leg_size.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">

                                      {dbuser !== null
                                        ? dbuser.next_strong_leg_users
                                        : "0"}{" "}

                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Strong Leg Size
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-one">
                                  <img
                                    src="assets/img/svg/other_leg.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.next_other_leg).toFixed(2)
                                        : "0"}{" "}
                                      {/* (
                                      {dbuser !== null
                                        ? dbuser.next_other_leg_users
                                        : "0"}
                                      ) */}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Other Leg
                                  </p>
                                  <p className="nftmax-history__amount" />
                                </div>
                              </div>
                              <div className="nftmax-history__canvas">
                                <div className="charts-main__one">
                                  <canvas
                                    id="myChart_history_one"
                                    width={224}
                                    height={112}
                                    style={{
                                      display: "none",
                                      boxSizing: "border-box",
                                      height: "112px",
                                      width: "224px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-one">
                                  <img
                                    src="assets/img/svg/other_leg_size.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">

                                      {dbuser !== null
                                        ? dbuser.next_other_leg_users
                                        : "0"}

                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Other Leg Size
                                  </p>
                                  <p className="nftmax-history__amount" />
                                </div>
                              </div>
                              <div className="nftmax-history__canvas">
                                <div className="charts-main__one">
                                  <canvas
                                    id="myChart_history_one"
                                    width={224}
                                    height={112}
                                    style={{
                                      display: "none",
                                      boxSizing: "border-box",
                                      height: "112px",
                                      width: "224px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-two">
                                  <img
                                    src="assets/img/svg/eligibility.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      {dbuser !== null
                                        ? dbuser.eligibility
                                        : "0"}
                                      x
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Eligibility
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* last row */}
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-three">
                                  <img
                                    src="assets/img/svg/cp_withdraw.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.t_cold_withdrawal).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    C P Withdrawal
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-four">
                                  <img
                                    src="assets/img/svg/cp_balance.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.t_cold_pocket_balance).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    C P Balance
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-one">
                                  <img
                                    src="assets/img/svg/mp_withdraw.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.t_other_withdrawal).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    M P Withdrawal
                                  </p>
                                  <p className="nftmax-history__amount" />
                                </div>
                              </div>
                              <div className="nftmax-history__canvas">
                                <div className="charts-main__one">
                                  <canvas
                                    id="myChart_history_one"
                                    width={224}
                                    height={112}
                                    style={{
                                      display: "none",
                                      boxSizing: "border-box",
                                      height: "112px",
                                      width: "224px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-two">
                                  <img
                                    src="assets/img/svg/mp_balance.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.t_other_pocket_balance).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    M P Balance
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-two">
                                  <img
                                    src="assets/img/svg/total_business.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.team_business).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Total Business
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                          <div className="nftmax-history mg-top-40">
                            <div className="nftmax-history__main">
                              <div className="nftmax-history__content">
                                <div className="nftmax-history__icon nftmax-history__icon-two">
                                  <img
                                    src="assets/img/svg/today_business.svg"
                                    alt="#"
                                  />
                                </div>
                                <div className="nftmax-history__text">
                                  <h4 className="nftmax-history__number">
                                    <span className="number">
                                      ${" "}
                                      {dbuser !== null
                                        ? Number(dbuser.user_today_teambusiness).toFixed(2)
                                        : "0"}
                                    </span>
                                  </h4>
                                  <p className="nftmax-history__text">
                                    Today Business
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="nftmax-table mg-top-40">
                            <div className="nftmax-table__heading">
                              <h3 className="nftmax-table__title mb-0">
                                Todays Referrals
                              </h3>
                            </div>
                            <div className="tab-content" id="myTabContent">
                              <div
                                className="tab-pane fade show active"
                                id="table_1"
                                role="tabpanel"
                                aria-labelledby="table_1"
                              >
                                <table
                                  id="nftmax-table__main"
                                  className="nftmax-table__main nftmax-table__main-v1"
                                >
                                  <thead className="nftmax-table__head">
                                    <tr>
                                      <th className="nftmax-table__column-1 nftmax-table__h1">
                                        Address
                                      </th>
                                      <th className="nftmax-table__column-2 nftmax-table__h2">
                                        Stake($)
                                      </th>
                                      {/* <th className="nftmax-table__column-3 nftmax-table__h3">
                                        BNB
                                      </th> */}
                                      <th className="nftmax-table__column-4 nftmax-table__h4">
                                        Block
                                      </th>
                                      <th className="nftmax-table__column-5 nftmax-table__h5">
                                        Reward Eligibility
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="nftmax-table__body">
                                    {data.map((ele, i) => {
                                      return (
                                        <tr>
                                          <td className="nftmax-table__column-1 nftmax-table__data-1">
                                            <p className="nftmax-table__text nftmax-table__bid-text">
                                              {ele.address}
                                            </p>
                                          </td>
                                          <td className="nftmax-table__column-2 nftmax-table__data-2">
                                            <div className="nftmax-table__amount nftmax-table__text-one">
                                              <span className="nftmax-table__text">
                                                $ {ele.t_staking}
                                              </span>
                                            </div>
                                          </td>
                                          {/* <td className="nftmax-table__column-2 nftmax-table__data-2">
                                            <div className="nftmax-table__amount nftmax-table__text-one">
                                              <span className="nftmax-table__text">
                                                {ele.matic} BNB
                                              </span>
                                            </div>
                                          </td> */}
                                          <td className="nftmax-table__column-3 nftmax-table__data-3">
                                            <div className="nftmax-table__amount nftmax-table__text-two">
                                              <span className="nftmax-table__text">
                                                {ele.b_type === 0
                                                  ? "Block A"
                                                  : "Block B"}
                                              </span>
                                            </div>
                                          </td>
                                          <td className="nftmax-table__column-4 nftmax-table__data-4">
                                            <p className="nftmax-table__text nftmax-table__up-down ">
                                              {ele.income_capability}x
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
