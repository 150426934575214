import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import { chainData } from "./connector/Connecter";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalError,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";

export const OnmaxContext = createContext();
const projectId = "b160e33890d1ff4e3a68400bbf56f4ba";
// const mainnet = {
//   chainId: 80001,
//   name: "Mumbai",
//   currency: "MATIC",
//   explorerUrl: "https://etherscan.io",
//   rpcUrl: "https://rpc-mumbai.maticvigil.com",
// };
const mainnet = {
  chainId: 56,
  name: "BSC Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
};


const metadata = {
  name: "Onmax",
  description: "",
  url: "https://stake.onmaxyield.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: 56,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function Onmaxcontext() {
  var { address, chainId, isConnected } = useWeb3ModalAccount();
  // address = "0x7fE926cD27d3fEbdA7570a1D84aED02555F3351F";
  const { disconnect } = useDisconnect()
  const { error } = useWeb3ModalError()


  const [balance, setBalance] = useState(0);
  const [DBbalance, setDBBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [rate, setrate] = useState(0);
  const [web3, setWeb3] = useState(null);
  const [esigner, setesigner] = useState(null);
  const [tokenrate, settokenrate] = useState(0);
  const [blocks, setblocks] = useState(null);
  const [ovrAddress, setovrAddress] = useState(null);
  const [smuser, setsmuser] = useState(null);
  const [dbuser, setdbuser] = useState(null);
  const [account, setaccount] = useState(address);
  const [stakcontract, setstakcontract] = useState(null);
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };
  const addchain = async () => {
    toastError("Connect wtih BSC Mainnet");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: chainData,
    });
  };
  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    console.log("link :- ", process.env.REACT_APP_BACKEND_LINK, process.env.REACT_APP_KEY);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  async function disconnectNow() {
    try {
      // deactivate();
      disconnect()
      localStorage.setItem("isconnected", false);
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      open();

      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };
  const getuserDB = async () => {
    var data = await getudata(account);
    if (data.data.error) {
      return "";
    }
    var dap = data.data.data;
    var fbln =
      dap.level_rew +
      dap.staking_rew +
      dap.heritage_rew +
      dap.community_rew +
      dap.super_bonus_rew +
      dap.community_royalty -
      dap.t_cold_withdrawal -
      dap.t_other_withdrawal;
    setDBBalance(Number(fbln).toFixed(2));

    setdbuser(data.data.data);
  };

  useEffect(() => {
    getuserDB();
    // }, [account]);
  }, [address,account]);
  useEffect(() => {
    // if (localStorage.getItem("isconnected") === "true") {
    //   connectMetamask();
    // }
    getSetting();
  }, []);

  // useEffect(() => {
  //   if (chainId !== 80001) {
  //     console.log("error", error);
  //     addchain();
  //   }
  // }, [chainId]);
  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer)
  }
  const getsmcs = async () => {
    console.log("erer", isConnected, settings.token);
    if (!isConnected || !settings.token || esigner === null) return;

    let contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    let maincontract = new ethers.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    setstakcontract(maincontract);
    var balance = await contract.balanceOf(account);
    var getrate = await maincontract.bnbrate();
    // var getrate = await maincontract.maticrate();
    var gettokenrate = await maincontract.tokenrate();
    console.log("gettokenrate", gettokenrate);
    setrate(Number(ethers.utils.formatUnits(getrate, 18)).toFixed(4));
    settokenrate(Number(ethers.utils.formatUnits(gettokenrate, 18)).toFixed(6));
    var minstakeblocka = await maincontract.minstakeblocka();
    var maxstakeblocka = await maincontract.maxstakeblocka();
    var minstakeblockb = await maincontract.minstakeblockb();
    var maxstakeblockb = await maincontract.maxstakeblockb();
    var blockss = {
      minstakeblocka: Number(ethers.utils.formatUnits(minstakeblocka, 18)),
      maxstakeblocka: Number(ethers.utils.formatUnits(maxstakeblocka, 18)),
      minstakeblockb: Number(ethers.utils.formatUnits(minstakeblockb, 18)),
      maxstakeblockb: Number(ethers.utils.formatUnits(maxstakeblockb, 18)),
    };
    console.log("blockss", blockss);
    setblocks(blockss);
    var user = await maincontract.user(account);
    setsmuser({
      referral: user._ref,
      mem: user._mem,
      contractaddress: user._contractaddress,
    });
    // console.log("getrate", blocks, getrate, user);
    setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

    // return { balance: b };
  };
  async function getTotalHolders() {
    try {
      console.clear()
      console.log("Api");
      const payload = {
        "jsonrpc": "2.0",
        "method": "ankr_getTokenHoldersCount",
        "params": {
          "blockchain": "bsc",
          "contractAddress": "0xc7E9841aFc184485e6A3375bC59cE2F43e9f738A",
          "pageSize": 10,
          "pageToken": ""
        },
        "id": 1
      };

      // Send POST request using Axios
      axios.post('https://rpc.ankr.com/multichain', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer '4b9c856569352e2c43d43d82099e13277156878f56b121cc1b4504f1f85ce84a'` // Include your API key here

        }
      })
        .then(response => {
          console.log('Total token holders:', response);
        })
        .catch(error => {
          console.error('Error:', error.message);
        });
    } catch (error) {
      console.error('Error:', error.message);
      return null;
    }
  }

  useEffect(() => {
    getTotalHolders();
  }, []);
  useEffect(() => {
    getsmcs();
    // }, [account, address,library, settings]);
  }, [isConnected, address,account, walletProvider, settings, esigner]);
  useEffect(() => {
    getSigner();
  }, [isConnected, walletProvider]);
  useEffect(() => {
    setaccount(address);
  }, [address]);
  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <OnmaxContext.Provider
        value={{
          settings: settings,
          library: ethers,
          balance,
          userdata,
          dbuser,
          getuserDB,
          web3,
          blocks,
          esigner,
          address,
          tokenrate,
          smuser,
          // account: ovrAddress ? ovrAddress : address ? address.toLowerCase() : address,
          account:account ? account.toLowerCase() : account,
          setaccount,
          connectInjected,
          disconnectNow,
          connectMetamask,
          formatAddress,
          toastError,
          toastSuccess,
          getudata,
          toastInfo,
          getsmcs,
          ethers,
          rate,
          copyaddress,
          stakcontract,
          DBbalance,
          setovrAddress
        }}
      >
        <App />
      </OnmaxContext.Provider>
    </>
  );
}

export default Onmaxcontext;
